.carousel-image {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 75vh;
}
.bg-img-1 {
    background-image: url('https://i.ibb.co/XLZVrKp/sorgo-1600x900.png');
}

.bg-img-2 {
    background-image: url('https://i.ibb.co/j4QrQqd/Guia-da-Soja-capa-1.png');
}

.bg-img-3 {
    background-image: url('https://i.ibb.co/9rXGfkZ/pexels-todd-trapani-1382102.jpg');
}