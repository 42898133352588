.not-found-container {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f7f7f7;
  }
  
  .not-found-content {
    text-align: center;
  }