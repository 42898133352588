a, strong, p {
    text-decoration: none !important; 
  } 
  .shadow-above {
    box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.1);
  }
  
.list-item{
    color: #002000;
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
}

.list-item:hover{
    color: #198754 !important;
    opacity: 0.7;
}