.small-card {
    height: auto;
    transition: transform 0.3s, filter 0.3s;
  }
.custom-card {
    position: relative;
    margin: 10px;
    transition: transform 0.3s, filter 0.3s;
  }
  
  .custom-card:hover {
    transform: scale(1.05);
  }
  
  .card-image {
    width: 100%;
    height: 45vh;
    display: block;
  }
  
  .card-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    opacity: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity 0.3s;
    flex-direction: column;
  }
  
  .custom-card:hover .card-overlay {
    opacity: 1;
  }
  
  .card-text {
    font-size: 14px;
    color: white;
    max-width: 80%;
    padding: 10px; 
    text-align: center;
    box-sizing: border-box; 
    white-space: normal; 
  }