.navbar{
    background: #fff;
}
.brand,.list-item{
    color: #002000;
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
}

.list-item:hover{
    color: #198754;
    opacity: 0.7;
}

.btn, .btn-success{
    background: #198754;
    border: none;
}

button.navbar-toggler.collapsed{
    background: #fff !important
}
button.navbar-toggler{
    background: #fff !important
}